<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<b-row class="justify-content-center">
				<div class="clearfix">
					<div
						id="logo_403"
						class="img-fluid mx-auto mb-5" />
					<p class="text-center h2 text-light ">
						<b-button
							variant="primary"
							@click="$router.go(-2)">
							{{ translate('go_back') }}
						</b-button>
					</p>
				</div>
			</b-row>
		</div>
	</div>
</template>

<script>
import { Validations as messages } from '@/translations';

export default {
	messages,
	name: 'Forbidden',
};
</script>
<style>
	.display-custom {
		font-size: 15rem
	}
</style>
